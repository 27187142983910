
            @import "src/assets/scss/_variables.scss";
          
.welcome-container {
  p {
    margin: 0;
  }
  section {
    height: 100vh;
    margin: 0 auto;
    max-width: 540px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;

    @media (max-width: 650px) {
      justify-content: start;
      margin-top: 50px;
      padding: 0 20px;
    }
  }

  border-bottom: dashed 2px $light-blue;

  h2 {
    margin: 0;
    font-size: 72px;
    font-weight: 900;
    @media (max-width: 650px) {
      font-size: 62px;
    }
  }
  p {
    font-size: 19px;
    font-weight: 400;
    @media (max-width: 650px) {
      font-size: 16px;
    }
  }

  .mouse-container {
    left: calc((100% + 300px) / 2);
    bottom: 20px;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    position: absolute;
    @media (max-width: 950px) {
      left: 50%;
      bottom: 5px;
      transform: translate(-50%, -15%);
    }
  }

  .mouse {
    border: solid 3px $light-blue;
    border-radius: 16px;
    display: block;
    margin: auto;
    height: 34px;
    position: relative;
    width: 25px;
    .wheel {
      background: $light-blue;
      border-radius: 100%;
      display: block;
      position: absolute;
      top: 8px;
      left: 50%;
      transform: translateX(-50%);
      height: 5px;
      width: 5px;
      -webkit-animation: ani-mouse 2s linear infinite;
      -moz-animation: ani-mouse 2s linear infinite;
      animation: ani-mouse 2s linear infinite;
    }
  }

  .wave-animation {
    display: inline-block;
    margin-left: 1rem;
    animation: wave-animation 2.5s infinite;
  }
}

@-webkit-keyframes ani-mouse {
  0% {
    top: 29%;
  }
  15% {
    top: 50%;
  }
  50% {
    top: 50%;
  }
  100% {
    top: 29%;
  }
}
@-moz-keyframes ani-mouse {
  0% {
    top: 29%;
  }
  15% {
    top: 50%;
  }
  50% {
    top: 50%;
  }
  100% {
    top: 29%;
  }
}
@keyframes ani-mouse {
  0% {
    top: 29%;
  }
  15% {
    top: 50%;
  }
  50% {
    top: 50%;
  }
  100% {
    top: 29%;
  }
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  15% {
    transform: rotate(14deg);
  }
  30% {
    transform: rotate(-8deg);
  }
  40% {
    transform: rotate(14deg);
  }
  50% {
    transform: rotate(-4deg);
  }
  60% {
    transform: rotate(10deg);
  }
  70% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
