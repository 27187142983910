
            @import "src/assets/scss/_variables.scss";
          
.experience-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: dashed 2px $light-blue;

  div.timeline {
    max-width: 400px;
    margin: auto;
    p > span {
      display: block;
      margin: 0.83em 0;
      font-size: 30px;
      font-weight: 900;
      text-transform: uppercase;
    }
  }

  div.timeline > div {
    border-top: 2px dashed;
    border-color: $light-blue !important;
    margin: 0;
    padding: 30px;
  }

  div.timeline > div:nth-child(even) {
    border-left: 2px dashed;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    margin-right: 30px;
    padding-right: 0;
  }

  div.timeline > div:nth-child(odd) {
    border-right: 2px dashed;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    margin-left: 30px;
    padding-left: 0;
  }

  div.timeline > div:first-child {
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  div.timeline > div:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  a {
    font-weight: 900;
    text-decoration: none;
    color: $lightest-blue;
  }
}
