$dark-blue: #12232E;
$light-blue: #007CC7;
$lightest-blue: #4DA8DA;
$shadow-dark-blue: #203647;
$shadow-light-blue: #EEFBFB;

$background-color: $dark-blue;
$text-color: $shadow-light-blue;
$contrast-blue: $lightest-blue;

$sidenav-offset: 300px;