
            @import "src/assets/scss/_variables.scss";
          
body {
  margin: 0;
  background-color: $dark-blue;
  color: $shadow-light-blue;
  font-family: "Montserrat", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}