
            @import "src/assets/scss/_variables.scss";
          
.fun-facts-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  section.fun-facts {
    display: flex;
    @media (max-width: 650px) {
      flex-direction: column;
      align-items: center;
    }
    width: 100%;
    justify-content: space-between;
    gap: 10px;
    text-align: center;
    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 30px;
      border: dashed 2px $light-blue;
      width: 33%;

      .fun-fact-number {
        font-size: 30px;
        font-weight: 700;
        line-height: 2em;
      }

      .fun-fact-description {
        font-size: 16px;
        line-height: 1em;
      }
    }
  }
}
