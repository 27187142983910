
            @import "src/assets/scss/_variables.scss";
          
.main-container {
  margin-left: $sidenav-offset + 20px;
  margin-right: 20px;
  @media (max-width: 950px) {
    margin-left: 0;
    margin-right: 0;
  }
}

section.content {
  padding: 0 100px;
  @media (max-width: 950px) {
    padding: 0 20px;
  }
}

.skills-container,
.experience-container,
.fun-facts-container {
  padding: 50px 0;
}
